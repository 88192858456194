import { Investigation } from '../types';
import { plugin } from '../module';
import { PluginExtensionEventHelpers } from '@grafana/data';

interface InvestigationContext {
  investigation: Investigation;
}

/**
 * Example of how to add a menu item to the Investigation dropdown menu
 * using Grafana's extension system.
 */
export function registerIncidentExtension() {
  plugin.addLink({
    title: 'Declare Incident',
    description: 'Create an incident from this investigation',
    icon: 'fire',
    targets: ['grafana-explorations-app/investigation-menu/v1.0'],
    onClick: (event: any, helpers: PluginExtensionEventHelpers<InvestigationContext>) => {
      const investigation = helpers.context?.investigation;

      if (!investigation) {
        console.error('No investigation context available');
        return;
      }

      if (!investigation.id) {
        console.error('Investigation ID is missing');
        return;
      }

      const baseUrl = window.location.origin;
      const investigationUrl = `${baseUrl}/a/grafana-explorations-app/investigation/${investigation.id}`;

      // Ensure proper encoding of parameters
      const title = encodeURIComponent(investigation.title);
      const url = encodeURIComponent(investigationUrl);
      const caption = encodeURIComponent(`Investigation: ${investigation.title}`);

      const incidentUrl = `${baseUrl}/a/grafana-incident-app?declare=new&title=${title}&url=${url}&caption=${caption}`;

      try {
        const newWindow = window.open(incidentUrl, '_blank');
        if (!newWindow) {
          console.error('Failed to open window - popup might be blocked');
        }
      } catch (error) {
        console.error('Error opening incident URL:', error);
      }
    },
  });
}
